import React, { FC } from "react"
import Layout from "../components/layout/Layout";
import HeadMeta from "../components/layout/HeadMeta";
import { graphql, PageProps } from "gatsby";
import { motion } from "framer-motion";
import Image from "../components/ui/Image"
import t from "../i18n"
import Button from "../components/ui/Button";
import { TypeImage, TypeLocale } from "../types";
import home from "../data/home.json"
import { fadeRight, hVariants, pVariants } from "../components/_animationVariants";

type IndexData = {
    intro: TypeImage
}
type IndexContext = {
    locale: TypeLocale
}

const IndexPage: FC<PageProps<IndexData, IndexContext>> = ( { pageContext, data } ) => {
    const { locale: l } = pageContext
    return (
        <Layout noPadding noContainer noContentPadding>
            <HeadMeta title={ t( "Home" ) }/>
            <div className={ "lg:flex hero lg:pt--0 pt--header" }>
                <section className={ "relative w--100 p--huge px--xxl flex--center-middle" }>
                    <div>
                        <motion.h1 variants={ pVariants } className={ "text--companion md:pl--xxl text--regular h2" }>{ home.subHeading[l] }</motion.h1>
                        <motion.h2 variants={ hVariants } className={ "text--accent md:text--xl-1 text--xl-1 text--bold" }>{ home.heading[l] }</motion.h2>
                        <div className={ "hero__intro relative max-w--80ch md:pl--huge pl:xxl md:pt--xxl md:mt--xxl pt--lg mt--lg pb--lg" }>
                            <motion.div variants={ fadeRight } className={ "hero__intro__line" }/>
                            { home.list.map( item =>
                                <motion.div variants={ fadeRight } className={ "mt--lg flex--center" } key={ item.title.en }>
                                    <svg viewBox="0 0 512 512" height={ 40 } fill={ "var(--companion)" }>
                                        <path d={ item.icon }/>
                                    </svg>
                                    <div className={ "h5 ml--lg w--100" }>{ item.title[l] }</div>
                                </motion.div>
                            ) }
                        </div>
                        <motion.div variants={ hVariants }>
                            <Button className={ "mt--lg md:ml--xl" } look={ "liquid" } to={ "/contact" }>Book now!</Button>
                        </motion.div>
                        <motion.h3 variants={ hVariants } className={ "h6 text--companion mt--lg" }>...{ home.close[l] }</motion.h3>
                    </div>
                    <Waves className={ "absolute hero__waves z--6" }/>
                </section>
                <Image data={ data.intro.childImageSharp.gatsbyImageData } alt={ "LoL" } className={ "h--v100 lg:w--60 lg:mt--0 mt--header" }/>
            </div>
        </Layout>
    )
}

export default IndexPage

const Waves: FC<{ className?: string }> = ( { className } ) => (
    <svg className={ className } fill={ "var(--accent)" } height={ 40 } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 395 61.6">
        <path
            d="M71.4,1.9c-8.5,0-12.8,4.7-17.2,9.7c-4.6,5.1-9.3,10.3-18.7,10.3s-14.2-5.3-18.7-10.3C12.3,6.6,8,1.9-0.5,1.9v-2 c9.4,0,14.2,5.3,18.7,10.3c4.5,5,8.7,9.7,17.2,9.7s12.8-4.7,17.2-9.7C57.2,5.1,62-0.1,71.4-0.1c9.4,0,14.2,5.3,18.7,10.3 c4.5,5,8.7,9.7,17.2,9.7c8.5,0,12.8-4.7,17.2-9.7c4.6-5.1,9.3-10.3,18.7-10.3s14.2,5.3,18.7,10.3c4.5,5,8.7,9.7,17.2,9.7 c8.5,0,12.8-4.7,17.2-9.7c4.6-5.1,9.3-10.3,18.7-10.3c9.4,0,14.2,5.3,18.7,10.3c4.5,5,8.7,9.7,17.2,9.7c8.5,0,12.8-4.7,17.2-9.7 c4.6-5.1,9.3-10.3,18.7-10.3v0c0,0,0,0,0,0c9.4,0,14.2,5.3,18.7,10.3c4.5,5,8.7,9.7,17.2,9.7v2c-9.4,0-14.2-5.3-18.7-10.3 c-4.5-5-8.7-9.7-17.2-9.7c0,0,0,0,0,0v0c-8.5,0-12.8,4.7-17.2,9.7c-4.6,5.1-9.3,10.3-18.7,10.3c-9.4,0-14.2-5.3-18.7-10.3 c-4.5-5-8.7-9.7-17.2-9.7c-8.5,0-12.8,4.7-17.2,9.7c-4.6,5.1-9.3,10.3-18.7,10.3c-9.4,0-14.2-5.3-18.7-10.3c-4.5-5-8.7-9.7-17.2-9.7 s-12.8,4.7-17.2,9.7c-4.6,5.1-9.3,10.3-18.7,10.3c-9.4,0-14.2-5.3-18.7-10.3C84.2,6.6,79.9,1.9,71.4,1.9z M377.7,49.6 c-4.6-5.1-9.3-10.3-18.7-10.3c0,0,0,0,0,0v0c-9.4,0-14.2,5.3-18.7,10.3c-4.5,5-8.7,9.7-17.2,9.7s-12.8-4.7-17.2-9.7 c-4.6-5.1-9.3-10.3-18.7-10.3s-14.2,5.3-18.7,10.3c-4.5,5-8.7,9.7-17.2,9.7c-8.5,0-12.8-4.7-17.2-9.7c-4.6-5.1-9.3-10.3-18.7-10.3 c-9.4,0-14.2,5.3-18.7,10.3c-4.5,5-8.7,9.7-17.2,9.7s-12.8-4.7-17.2-9.7c-4.6-5.1-9.3-10.3-18.7-10.3c-9.4,0-14.2,5.3-18.7,10.3 c-4.5,5-8.7,9.7-17.2,9.7s-12.8-4.7-17.2-9.7c-4.6-5.1-9.3-10.3-18.7-10.3v2c8.5,0,12.8,4.7,17.2,9.7c4.6,5.1,9.3,10.3,18.7,10.3 s14.2-5.3,18.7-10.3c4.5-5,8.7-9.7,17.2-9.7c8.5,0,12.8,4.7,17.2,9.7c4.6,5.1,9.3,10.3,18.7,10.3S193.4,56.1,198,51 c4.5-5,8.7-9.7,17.2-9.7c8.5,0,12.8,4.7,17.2,9.7c4.6,5.1,9.3,10.3,18.7,10.3c9.4,0,14.2-5.3,18.7-10.3c4.5-5,8.7-9.7,17.2-9.7 s12.8,4.7,17.2,9.7c4.6,5.1,9.3,10.3,18.7,10.3c9.4,0,14.2-5.3,18.7-10.3c4.5-5,8.7-9.7,17.2-9.7v0c0,0,0,0,0,0 c8.5,0,12.8,4.7,17.2,9.7c4.6,5.1,9.3,10.3,18.7,10.3v-2C386.4,59.3,382.2,54.6,377.7,49.6z"/>
    </svg>

)

export const query = graphql`{
    intro: file(relativePath: {eq: "exterior/boat-exterior-7.jpg"}) {
        relativePath
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
}`
