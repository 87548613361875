export const fadeInParent = {
    init: {
        opacity: 0,
    },
    in  : {
        opacity   : 1,
        transition: {
            staggerChildren: .15,
        }
    },
    out : {
        opacity: 0,
    }
}
export const hVariants = {
    init: {
        opacity: 0,
        y      : -20,
    },
    in  : {
        opacity   : 1,
        y         : 0,
        transition: {
            type    : "tween",
            ease    : "easeInOut",
            duration: .5
        }
    },
    out : {
        opacity: 0,
        y      : -20,
    }
}

export const pVariants = {
    init: {
        opacity: 0,
        y      : 20,
    },
    in  : {
        opacity   : 1,
        y         : 0,
        transition: {
            type    : "tween",
            ease    : "easeInOut",
            duration: .5
        }
    },
    out : {
        opacity: 0,
        y      : 20,
    },
}

export const fadeLeft = {
    init: {
        opacity: 0,
        x      : -100
    },
    in  : {
        opacity   : 1,
        x         : 0,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity: 0,
        x      : -100,
    }
}

export const fadeRight = {
    init: {
        opacity: 0,
        x      : 100
    },
    in  : {
        opacity   : 1,
        x         : 0,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity: 0,
        x      : 100,
    }
}
export const fadeUp = {
    init: {
        opacity: 0,
        y      : 100
    },
    in  : {
        opacity   : 1,
        y         : 0,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity   : 0,
        y         : -100,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    }
}
export const fadeDown = {
    init: {
        opacity: 0,
        y      : -100
    },
    in  : {
        opacity   : 1,
        y         : 0,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity   : 0,
        y         : 100,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    }
}
export const zoomIn = {
    init: {
        opacity: 0,
        scale  : .8,
    },
    in  : {
        opacity   : 1,
        scale     : 1,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity: 0,
        scale  : .8
    },
}
export const zoomOut = {
    init: {
        opacity: 0,
        scale  : 1.2,
    },
    in  : {
        opacity   : 1,
        scale     : 1,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity: 0,
        scale  : 1.2
    },
}
export const menuLinkVariants = {
    init: {
        opacity: 0,
        y      : 50
    },
    in  : {
        opacity   : 1,
        y         : 0,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    },
    out : {
        opacity: 0,
        y      : 0
    }
}

export const menuVariants = {
    init: {
        y: "-100%"
    },
    in  : {
        y         : 0,
        transition: {
            type           : "tween",
            ease           : "easeInOut",
            staggerChildren: .15,
            when           : "beforeChildren",
        }
    },
    out : {
        y         : "100%",
        transition: {
            type: "tween",
            ease: "easeInOut",
            when: "afterChildren",
        }
    }
}
